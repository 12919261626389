.copy-rights {
	width: 100%;
	height: 50px;
	background-color: #eefff4;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 20px;
}

.copy-rights span {
	margin-bottom: 0;
	font-family: 'ubunturegular',"SolaimanLipiNormal", sans-serif;
	font-size: 14px;
	align-items: center;
	vertical-align: middle;
	/* line-height: 50px; */
}

.copy-rights span a {
	font-weight: 500;
	color: #2aa457 !important;
	text-decoration: none;
}