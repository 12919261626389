.donor {
	padding: 16px 0;
}

.donor .section-title {
	/* font-family: 'El Messiri', sans-serif; */
	font-weight: 700;
	font-size: 45px;
	text-align: center;
	color: #004018;
}

.donor .section-desc {
	/* font-family: 'El Messiri', sans-serif; */
	font-size: 16px;
	max-width: 700px;
	text-align: center;
	margin: 0 auto;
	color: #7c7c7c;
	margin-bottom: 50px;
}