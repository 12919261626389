.btn-round {
	width: 60px;
	height: 60px;
	box-shadow: 0 0 20px rgba(0, 64, 25, 0.3);
	border-radius: 50%;
	background-color: #ffffff;
	color: #2aa457;
	padding: 0;
	text-align: center;
	line-height: 60px;
	border: 0;
}

.btn-round:hover {
	background-color: #ffffff;
	color: #2aa457;
}

.btn-dsc {
	/* font-family: 'Poppins', sans-serif; */
	font-weight: 500;
	font-size: 14px;
	color: #004018;
	margin-left: 15px;
}