.news {
	padding: 1rem 0;
}

@media (min-width: 200px) and (max-width: 767.98px) {
	.news {
		background-size: 0px 0px !important;
	}
}

.news a {
	text-decoration: none !important;
}

.news .section-title {
	/* font-family: "El Messiri", cursive; */
	font-size: 3rem;
	text-align: center;
	color: #004018;
}

.news .section-desc {
	font-size: 14px;
	max-width: 700px;
	text-align: center;
	margin: 0 auto;
	color: #7c7c7c;
	margin-bottom: 50px;
}

.news .card {
	position: relative;
	transition: 0.3s ease-in-out;
}

.news .card:hover {
	box-shadow: 5px 10px 15px rgba(0, 64, 25, 0.3);
}

.news .card .card-body {
	padding: 10px 20px;
}

.news .card .card-body .location {
	font-size: 11px;
	font-weight: 500;
	margin-bottom: 10px;
	color: #8e9992;
}

.news .card .card-body .location span {
	margin-right: 10px;
	color: #2aa457;
}

.news .card .card-body h6 {
	/* font-family: "El Messiri", cursive; */
	display: -webkit-box;
	font-weight: 700;
	max-width: 100%;
	margin: 0 auto;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 25px;
	color: #004018;
}

.news .card .card-footer {
	background-color: transparent;
}

/* .news .card .card-footer {
	font-family: "Poppins", sans-serif;
} */

.news .card .card-footer .arrow {
	width: 2rem;
	height: 2rem;
	/* border: 2px solid #2aa457; */
	border-radius: 50%;
	/* margin-right: 10px; */
}

.news .card .card-footer span {
	font-size: 12px;
	color: #9c9c9c;
}

.news .card .card-footer Button {
	margin-left: auto;
	background-color: transparent;
	padding: 0;
	border: 0;
	color: #ee1f49;
	height: 2rem;
	font-size: 1.5rem;
	line-height: 2rem;
}

.news .card .card-footer Button span {
	margin-left: 0.5rem;
	line-height: 2rem;
	font-size: 1rem;
	/* font-weight: 500; */
}


.card .donation {
	width: 9rem;
	height: 2rem;
	padding: 0.25rem;
	position: absolute;
	top: 1rem;
	left: 1rem;
	background-color: #2aa457;
	text-align: center;
}



.card .donation span {
	text-transform: capitalize;
	font-size: 1rem;
	color: #ffffff;
}