.counter {
	display: flex;
	margin-bottom: 30px;
}

.counter div {
	padding: 10px 30px;
	position: relative;
}

.counter div:nth-child(2)::after {
	content: "";
	position: absolute;
	width: 2px;
	height: 40px;
	top: 25px;
	left: 0;
	background-color: #c4c4c4;
}

.counter div:nth-child(2)::before {
	content: "";
	position: absolute;
	width: 2px;
	height: 40px;
	top: 25px;
	right: 0;
	background-color: #c4c4c4;
}

.counter div:nth-child(1) {
	padding-left: 0;
	color: #2aa457;
}

.counter div:nth-child(2) {
	color: #fee600;
}

.counter div:nth-child(3) {
	color: #004018;
}

.counter div span {
	color: #004018;
}