.fundrise {
	position: relative;
	width: 230px;
	height: 280px;
	border-radius: 15px 15px 15px 60px;
	background-color: #ffffff;
	box-shadow: 0 0 40px rgba(0, 64, 25, 0.5);
	padding: 15px;
}

.fundrise img {
	width: 100%;
	height: auto;
	border-radius: 10px;
}

.fundrise .chk {
	position: absolute;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 3px solid #fee600;
	font-size: 15px;
	background-color: #ffffff;
	color: #2aa457;
	box-shadow: 0 5px 20px rgba(0, 64, 25, 0.5);
	padding: 0;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	left: calc(50% - 20px);
	bottom: 45px;
}

.fundrise p {
	margin-bottom: 0;
	font-weight: 500;
	color: #2aa457;
	text-align: center;
	margin-top: 25px;
}

@media (min-width: 250px) and (max-width: 767.98px) {
	.fundrise {
		width: 180px;
		height: 230px;
		padding: 10px;
		border-radius: 10px 10px 10px 40px;
	}
}