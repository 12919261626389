.activities {
	padding: 16px 0;
}

/* @media (min-width: 768px) and (max-width: 991.98px) {
    .activities {
        background-position: left top 50px!important;
        background-size: auto 500px !important;
    }
} */

@media (min-width: 300.98px) and (max-width: 767.98px) {
	.activities {
		background-position: left top !important;
		background-size: 300px auto !important;
	}
}

.activities .service-wrap span {
	width: 100px;
}

.activities .service-wrap .icon {
	height: 80px;
	width: auto;
}

.activities .service-wrap .info h1 {
	font-family:  'ubunturegular',"SolaimanLipiNormal", sans-serif;
	color: #004018;

}

.activities .service-wrap .info span {
	font-family: 'ubunturegular',"SolaimanLipiNormal", sans-serif;
	font-size: 16px;
	font-weight: 500;
	color: #8c8d8c;
}

.activities .worldwide-doner {
	width: 100%;
	height: auto;
}

@media (min-width: 250px) and (max-width: 991.98px) {
	.activities .col-6 {
		justify-content: center;
	}
}

@media (min-width: 250px) and (max-width: 991.98px) {
	.activities .col-6 span {
		width: 50px;
	}

	.activities .col-6 .icon {
		height: 40px;
	}

	.activities .service-wrap .info h1 {
		font-family: 'ubunturegular',"SolaimanLipiNormal", sans-serif, cursive;
		color: #004018;
		font-size: 30px;
		font-weight: 700;
		margin-bottom: 0;
	}

	.activities .service-wrap .info span {
		font-size: 12px !important;
	}
}