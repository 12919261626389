.donate-amount label {
	font-size: 1rem !important;
	font-weight: bold;
}

@media(min-width:425px) {
	.control-flow {
		clear: both;
	}
}

@media(min-width:576px) {
	.control-flow {
		clear: both;
		height: 100%;
	}
}

@media(min-width:768px) {
	.control-flow {
		overflow: auto;
		height: 100%;
	}
}

.btn-check:checked+.btn-outline-success,
.btn-check:active+.btn-outline-success,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show {
	background-color: #7dd078 !important;
	border-color: #7dd078 !important;
}

.btn-outline-success:hover {
	background-color: #7dd078 !important;
	border-color: #7dd078 !important;
}

.donate-entry  .ant-collapse-header {
  display:none !important
}
.donate-entry .ant-collapse-content {
  border-top: none !important;
}
.donate-entry .ant-collapse.close-collapse{
  border-top: none;
}
.donate-entry .close-collapse .ant-collapse-item {
  border-bottom: none !important;
}

/* 
@media(min-width:425px) {
	.control-flow{
		clear: both;
	}
}
@media(min-width:576px) {
	.control-flow{
		clear: both;
		height: 100%;
	}
}
@media(min-width:768px) {
	.control-flow{
		overflow: auto;
		height: 100%;
	}
}

@media(min-width:1024px) {
	.control-flow{
		overflow: auto;
		height: 100%;
	}
}

@media(min-width:1280px) {
	.control-flow{
		overflow: auto;
		height: 100%;
	}
}
.control-height{
	height: 100%;
} */



/* 
@media(min-width:320px) {
	.c-height{
		min-height: 5vh;
	}
}

@media(min-width:425px) {
	.c-height{
		min-height: 6vh;
	}
}
@media(min-width:576px) {
	.c-height{
		min-height: 30vh;
	}
}

@media(min-width:640px) {
	.c-height{
		min-height: 10vh;
	}
}


@media(min-width:768px) {
	.c-height{
		min-height: 70vh;
	}
}
@media(min-width:850px) {
	.c-height{
		min-height: 100vh;
	}
}
@media(min-width:992px) {
	.c-height{
		min-height: 100vh;
	}
}
@media(min-width:1200px) {
	.c-height{
		min-height: 100vh;
	}
}
@media(min-width:1400px) {
	.c-height{
		min-height: 100vh;
	}
} */