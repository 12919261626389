@font-face {
	font-family: 'ubuntubold';
	src: url('fonts/Ubuntu/ubuntu-b-webfont.woff2') format('woff2'),
		url('fonts/Ubuntu/ubuntu-b-webfont.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: fallback;
}

@font-face {
	font-family: 'ubunturegular';
	src: url('fonts/Ubuntu/ubuntu-r-webfont.woff2') format('woff2'),
		url('fonts/Ubuntu/ubuntu-r-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: fallback;
}




/* 
@font-face {
	font-family: 'ubuntubold_italic';
	src: url('./fonts/Ubuntu/ubuntu-bi-webfont.woff2') format('woff2'),
		url('./fonts/Ubuntu/ubuntu-bi-webfont.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: fallback;
}


@font-face {
	font-family: 'ubuntu_condensedregular';
	src: url('./fonts/Ubuntu/ubuntu-c-webfont.woff2') format('woff2'),
		url('./fonts/Ubuntu/ubuntu-c-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: fallback;
}


@font-face {
	font-family: 'ubuntulight';
	src: url('./fonts/Ubuntu/ubuntu-l-webfont.woff2') format('woff2'),
		url('./fonts/Ubuntu/ubuntu-l-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: fallback;
}


@font-face {
	font-family: 'ubuntulight_italic';
	src: url('./fonts/Ubuntu/ubuntu-li-webfont.woff2') format('woff2'),
		url('./fonts/Ubuntu/ubuntu-li-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: fallback;
}


@font-face {
	font-family: 'ubuntumedium';
	src: url('./fonts/Ubuntu/ubuntu-m-webfont.woff2') format('woff2'),
		url('./fonts/Ubuntu/ubuntu-m-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: fallback;
}


@font-face {
	font-family: 'ubuntumedium_italic';
	src: url('./fonts/Ubuntu/ubuntu-mi-webfont.woff2') format('woff2'),
		url('./fonts/Ubuntu/ubuntu-mi-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: fallback;
} */




/* @font-face {
	font-family: 'ubuntuitalic';
	src: url('./fonts/Ubuntu/ubuntu-ri-webfont.woff2') format('woff2'),
		url('./fonts/Ubuntu/ubuntu-ri-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: fallback;
} */


html {
	font-size: 100%;
	line-height: 1.5;
}

body {
	background-color: #eefff4 !important;
	margin: 0;
	font-family:  "ubunturegular","SolaimanLipiNormal", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif !important;
	font-size: 1rem !important;
	line-height: 1.5 !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

p,
label {
	font-family:  'ubunturegular',"SolaimanLipiNormal";
	font-size: 1rem;
	line-height: 1.5;
}

.btn-success {
	background: #7dd078 !important;
	box-shadow: none !important;
	border-radius: 0;
	border: none !important;
	transition: background 0.5s;
}

.btn-success:hover {
	background: #4be143 !important;
}


code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.backface {
	backface-visibility: hidden;
}



.card-btn {
	position: absolute;
	text-align: center;
	bottom: 1px;
}


.card-img-full:active {
	transform: rotate(45deg);
}

.card-img,
.card-img-bottom {
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

.card-img,
.card-img-top {
	border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important;
}

/* .cart {
	padding-left: 2rem !important;
	padding-right: 2.5rem !important;
	font-size: 1.25rem !important;
} */

a.nav-link {
	color: green;
}


.gift {
	color: #209419;
}

.header {
	font-size: 0.9rem;
	font-family: "ubuntubold","SolaimanLipiNormal";
}

.font-face-ub {
	font-family:  "ubuntubold","SolaimanLipiNormal";
}


.header-register {
	font-size: 1rem !important;
	color: white !important;
	background-color: #7dd078;
	padding-right: 1rem;
	padding-left: 1rem;
	font-family: "ubuntubold","SolaimanLipiNormal";
	border-radius: 3px;
}

.header-register:hover {
	background-color: #209419;
	color: white !important;
}

.register-text {
	margin-left: .2rem;
	padding-top: 1rem !important;
}

/* 
@media(min-width:320px) {
	.header-register {
		padding-left: 0.5rem !important;
	}
}

@media(min-width:425px) {
	.header-register {
		padding-left: 1rem !important;
	}
}

@media(min-width:768px) {
	.header-register {
		padding-left: 2rem !important;
	}
}

@media(min-width:1024px) {
	.header-register {
		padding-left: 3rem !important;
	}
} */





.headers {
	background: white;
	font-family: "ubuntubold","SolaimanLipiNormal";
}

/* .img-height {
  height: 180px !important;
} */
.imglist {
	left: 0% !important;
}

.nav-lower {
	font-size: 0.75rem;
	background: #7dd078;
	font-family: "UbuntuBold","SolaimanLipiNormal";
}

.navbar {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}

.ant-btn-primary {
	background: #78d673 !important;
	border-color: #78d673 !important;
}




/* .row-green {
  background: #7dd078;
} */

.slick-arrow {
	z-index: 999;
	/* padding-top: 0.25rem !important; */
}

.slick-prev {
	left: 1.5rem;
	/* margin-top: 0.125rem; */
}

.slick-next {
	right: 1.5rem;
	/* margin-top: 0.125rem; */
}


@media(min-width:320px) {
	.slick-arrow {
		padding-top: 0.5rem !important;
	}

	.slick-prev {
		margin-top: 0.5rem;
	}

	.slick-next {
		margin-top: 0.5rem;
	}

}

@media(min-width:425px) {
	.slick-arrow {
		padding-top: 0.5rem !important;
	}

	.slick-prev {
		margin-top: 0.25rem;
	}

	.slick-next {
		margin-top: 0.25rem;
	}

}

@media(min-width:768px) {
	.slick-arrow {
		padding-top: 0.25rem !important;
	}

	.slick-prev {
		margin-top: 0.125rem;
	}

	.slick-next {
		margin-top: 0.125rem;
	}

}

@media(min-width:1440px) {
	.slick-arrow {
		padding-top: 0.125rem !important;
	}

	.slick-prev {
		margin-top: 0.125rem;
	}

	.slick-next {
		margin-top: 0.125rem;
	}

}

@media(min-width:2560px) {
	.slick-arrow {
		padding-top: 0.125rem !important;
	}

	.slick-prev {
		margin-top: 0.0625rem;
	}

	.slick-next {
		margin-top: 0.0625rem;
	}

}



.slick-arrow.slick-prev::before {
	content: "\25C1";
}

.slick-arrow.slick-next::before {
	content: "\25B7";
}

.slick-arrow.slick-next::before,
.slick-arrow.slick-prev::before {
	font-size: 2.5rem;
	line-height: inherit;
}

.slick-slide div {
	margin-bottom: -0.3rem;
}

.full-image-close {
	position: absolute;
	left: 0.35rem;
	top: 0.35rem;
	padding: 0;
	margin: 0;
	font-size: 1.25rem;
	/* background-color: #209419; */
	color: white;

	line-height: 1.5;
	padding: 0 0.35rem;
	font-weight: bold;

	cursor: pointer;
	z-index: 999;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "ubuntubold","SolaimanLipiNormal";
}

.poverty-text {
	font-size: 0.7rem !important;
	margin-top: 0.35rem !important;
	color: black;
	font-family: "ubuntubold";
}

.poverty {
	font-size: 1.25rem !important;
	margin-top: -1rem !important;
	font-weight: bold;
	font-family: "ubuntubold";
}

.hand-icon {
	font-size: 1.75rem !important;
	fill: green !important;
	color: #209419 !important;
}

.form-check-input:checked {
	background: #209419;
	color: white;
	z-index: -1;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

@media(min-width:320px) {
	html {
		font-size: 65%;
	}
}

@media(min-width:425px) {
	html {
		font-size: 75%;
	}
}

@media(min-width:768px) {
	html {
		font-size: 75%;
	}
}

@media(min-width:1024px) {
	html {
		font-size: 90%;
	}
}

@media(min-width:1440px) {
	html {
		font-size: 100%;
	}
}

@media(min-width:2560px) {
	html {
		font-size: 150%;
	}
}

/* animation */

.ant-btn-primary:focus, .ant-btn-primary:hover{
	color: white !important;
}


:root {
	--animate-duration: 800ms;
	--animate-delay: .9s;
	--xyz-duration-default: 1s;
	--xyz-ease-default: ease;
	---xyz-delay-default: 0.5s;
}