.ant-steps-item-process .ant-steps-item-icon {
	background-color: #78d673 !important;
	border-color: #78d673 !important;
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
	color: #78d673 !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
	border-color: #78d673 !important;
}

.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
	background: #78d673 !important;
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
	background: #78d673 !important;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail:after {
	background-color: #78d673 !important;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
	color: #78d673 !important;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
	color: #78d673 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
	background-color: #78d673 !important;
	border-color: #78d673 !important;
}

.ant-steps-item-title {
	font-size: 0.75rem !important;
}