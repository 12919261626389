.sadakah {
	margin-top: 8px;
	padding: 16px 0;
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.sadakah {
		background-position: left top, left bottom, right bottom -300px;
		background-size: 560px auto, 150px auto, auto 500px;
	}
}

@media (min-width: 300px) and (max-width: 767.98px) {
	.sadakah {
		background-position: left top, left bottom, right bottom -100px !important;
		background-size: auto 500px, 100px auto, auto 350px !important;
	}
}

.sadakah .info-wrap h1 {
	/* font-family: "El Messiri", sans-serif; */
	font-size: 45px;
	line-height: 70px;
	font-weight: 700;
	color: #004018;
}

.sadakah .info-wrap h1 span {
	color: #2aa457;
}

.sadakah .info-wrap .short-desc {
	font-size: 14px;
	font-weight: 500;
	color: #777978;
	line-height: 25px;
}

.sadakah .info-wrap .short-desc span {
	/* font-family: 'El Messiri', sans-serif; */
	font-size: 20px;
	font-weight: 600;
	color: #2aa457;
}

.sadakah .donantion-btn-group {
	margin-top: 50px;
}

.sadakah .img-wrap .big-img {
	width: 100%;
	height: auto;
}