.ant-btn.active {
	color: white !important;
}

.ant-btn.active:hover {
	color: white !important;
}

.ant-btn:hover {
	color: #7dd078 !important;
}

.ant-btn {
	border: 1px solid #7dd078 !important;
}