footer {
	padding: 16px 0;
	margin-top: 10rem;
}

footer .footer-logo {
	margin-bottom: 20px;
}

footer .short-info {
	font-size: 14px;
	color: #12331e;
}

footer .footer-contact {
	list-style: none;
	margin: 0;
	padding: 0;
}

footer .footer-contact li {
	font-size: 14px;
	color: #12331e;
}

footer .footer-contact li span {
	width: 20px;
	height: auto;
	font-size: 18px;
	padding-right: 10px;
	color: #2aa457;
}

footer h6 {
	font-size: 18px;
	color: #12331e;
	margin-bottom: 30px;
}

footer .list-unstyled li {
	margin-bottom: 15px;
}

footer .list-unstyled li a {
	font-size: 14px;
	font-weight: 500;
	color: #12331e;
	text-decoration: none;
	transition: .3s ease-in-out;
}

footer .list-unstyled li a:hover {
	color: #2aa457;
	padding-left: 6px;
}

footer .list-unstyled li a span {
	font-size: 12px;
}

footer .input-group {
	height: 50px;
}

footer .input-group input {
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
	font-size: 14px;
	padding-left: 15px;
}

footer .input-group .btn {
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
	background-color: #2aa457;
	color: #ffffff;
}

footer .from-text {
	color: #5a5c5a;
}

footer .social-link {
	display: flex;
	margin-top: 30px;
}

footer .social-link li:not(:last-child) {
	margin-right: 15px;
}

footer .social-link li a {
	display: block;
	width: 34px;
	height: 34px;
	background-color: #eefff4;
	border-radius: 6px;
	text-align: center;
	line-height: 34px;
	font-size: 16px;
	color: #2aa457;
}

footer .social-link li a:hover {
	background-color: #2aa457;
	color: #ffffff;
	padding-left: 0;
}