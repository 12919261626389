.qarz {
	padding: 16px 0;
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.qarz {
		background-position: right top -200px;
		background-size: auto 500px;
	}
}

@media (min-width: 300px) and (max-width: 767.98px) {
	.qarz {
		background-position: right top -250px !important;
		background-size: auto 350px !important;
	}

	.qarz .img-wrap .big-img-wrap .big-img {
		width: 50%;
	}
}

@media (min-width: 250px) and (max-width: 575.98px) {
	.qarz .img-wrap .big-img-wrap .big-img {
		width: 100%;
		height: 350px !important;
		margin-left: 40px !important;
		margin-top: -40px !important;
	}

	.qarz .img-wrap .big-img-wrap .img-box,
	.qarz .img-wrap .big-img-wrap .img-box-bottom {
		padding: 5px !important;
		border-radius: 10px !important;
	}

	.qarz .img-wrap .big-img-wrap .img-box-bottom {
		margin-top: -40px !important;
	}
}

.qarz .img-wrap .big-img-wrap .img-box,
.qarz .img-wrap .big-img-wrap .img-box-bottom {
	width: 100%;
	height: auto;
	background-color: #ffffff;
	border-radius: 15px;
	box-shadow: 0 0 30px rgba(0, 64, 25, 0.5);
	padding: 10px;
	text-align: center;
}

.qarz .img-wrap .big-img-wrap .img-box img,
.qarz .img-wrap .big-img-wrap .img-box-bottom img {
	width: 100%;
	height: auto;
	border-radius: 6px;
}

.qarz .img-wrap .big-img-wrap .big-img {
	width: 100%;
	height: 550px;
	border-radius: 80px 0 0 0;
	background-color: red;
	margin-left: 80px;
	margin-top: -100px;
	position: relative;
	overflow: hidden;
	display: flex;
	justify-content: center;
}

.qarz .img-wrap .big-img-wrap .big-img img {
	height: 100%;
	width: auto;
}

.qarz .img-wrap .big-img-wrap .img-box-bottom {
	margin-top: -120px;
	position: relative;
}

/* .qarz .img-wrap .fundrise {
    position: relative;
    width: 230px;
    height: 280px;
    border-radius: 15px 15px 60px 15px;
    background-color: #ffffff;
    box-shadow: 0 0 40px rgba(0, 64, 25, 0.5);
    padding: 15px;
    margin-left: auto;
    margin-top: -200px;
}

.qarz .img-wrap .fundrise img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.qarz .img-wrap .fundrise .chk {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 3px solid #fee600;
    font-size: 15px;
    background-color: #ffffff;
    color: #2aa457;
    box-shadow: 0 5px 20px rgba(0, 64, 25, 0.5);
    padding: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    left: calc(50% - 20px);
    bottom: 45px;
}

.qarz .img-wrap .fundrise p {
    margin-bottom: 0;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: #2aa457;
    text-align: center;
    margin-top: 25px;
} */

.qarz .info-wrap p {
	font-size: 14px;
	color: #777978;
	line-height: 25px;
}

.qarz .info-wrap p span {
	/* font-family: 'El Messiri', sans-serif; */
	font-size: 20px;
	font-weight: 600;
	color: #2aa457;
}

.qarz .donantion-btn-group {
	margin-top: 50px;
}

.qarz .info-wrap h1 {
	/* font-family: 'El Messiri', sans-serif; */
	font-size: 45px;
	line-height: 70px;
	font-weight: 700;
	color: #004018;
}

.qarz .info-wrap h1 span {
	color: #2aa457;
}