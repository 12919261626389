@keyframes pop{
	0%{
		opacity: 0.9;
	}
	30%{
		transform: perspective(5rem) translateZ(-0.1rem);
	}
	100%{
		opacity: 1;
		transform: perspective(5rem) translate(0);
	}
}

.image-hover{
	transition: opacity 1s;
}


.image-hover:hover{
	cursor: pointer;
	opacity: 0.7;
	/* animation-name: pop;
	animation-duration: 1s; */
}

@keyframes popProfile{
	from{
		opacity: 0;
		transform: perspective(250px) translatez(50px);
	}
	to{
		opacity: 1;
		transform: perspective(250px) translate(0);
	}
}

.animate{
	animation-name: popProfile;
	animation-duration: 1.5s;
}


.slider-bottom-margin{margin-bottom: -0.25rem !important;}

/* .test .row .col-md-12{
	padding-left: 0 !important;
	padding-right: 0 !important;
}
.test .row .col-md-6{
	padding-left: 0 !important;
	padding-right: 0 !important;
} */
