.btn-big {
	height: 60px;
	background-color: #2aa457;
	color: #ffffff;
	font-size: 14px;
	box-shadow: 0 0 20px rgba(0, 64, 25, 0.3);
	border: 0;
	padding: 0 30px;
	text-align: center;
	line-height: 60px;
	margin-right: 50px;
}

.btn-big:hover {
	color: #ffffff;
	background-color: #2aa457;
}

@media (min-width: 250px) and (max-width: 767.98px) {
	.btn-big {
		margin-right: 20px;
	}
}