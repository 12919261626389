.slick-next:before,
.slick-prev:before {
	color: white;
}

.slider div img {
	width: 100%;
	height: auto;
	padding: 5px;
}

@media (min-width: 250px) and (max-width: 991.98px) {

	.slick-next,
	.slick-prev {
		display: none !important;
	}
}