.delim{
	--bs-breadcrumb-divider: '>';
}
a{
	color: #6c757d !important;
}
a:hover{
	color: green !important;
}
.breadcrumb{
	margin-bottom:0 !important
}