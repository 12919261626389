.App {
  text-align: center;
}

.App-logo {
  height: 2.5rem;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: none;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.font-face-ub {
	font-family: "UbuntuBold","SolaimanLipiNormal" !important;
 }
 .form-control:focus {
	border-color: #7dd078  !important;
	box-shadow: 0 0 0 0.2rem #7dd078  !important;
} 
.ant-select-item-option-selected {
	background-color: #7dd078  !important;
}


 
 
