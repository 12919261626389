.profile-detail {
	padding-left: 1px !important;
	padding-right: 1px !important;
}

.gallery {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.btn-new {
	background: #209419 !important;
	box-shadow: none !important;
	border-radius: 0 !important;
	border: none !important;
	color: white !important;
	/* border: none !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border-radius: 0px !important; */
}

.btn-gallery {
	background-color: rgb(235, 225, 225) !important;
	border-radius: 0 !important;
	border: 0 !important;
	margin-right: 3px !important;
}

.btn-analysis {
	background-color: rgb(235, 225, 225) !important;
	border-radius: 0 !important;
	border: 0 !important;
	margin-left: 3px !important;
}

.btn-analysis:hover {
	background-color: aliceblue !important;
}

.btn-gallery:hover {
	background-color: aliceblue !important;
}

@media(min-width:320px) {
	.flexbox-container {
		width: 2.5rem;
		height: 1.5rem;
	}
}

@media(min-width:375px) {
	.flexbox-container {
		width: 2.5rem;
		height: 1.5rem;
	}
}

@media(min-width:425px) {
	.flexbox-container {
		width: 2rem;
		height: 1.25rem;
	}
}

@media(min-width:576px) {
	.flexbox-container {
		width: 2rem;
		height: 1.25rem;
	}
}

@media(min-width:640px) {
	.flexbox-container {
		width: 2rem;
		height: 1rem;
	}

	.position-rel {
		position: relative !important
	}
}


@media(min-width:768px) {
	.donate-entry-wrap{
		position: absolute;
	}
	.flexbox-container {
		width: 1.5rem;
		height: 1rem;
	}

	.position-rel {
		position: absolute !important
	}
}

@media(min-width:1024px) {
	.btn-sz {
		font-size: 1.25rem !important;
	}

	.flexbox-container {
		width: 2rem;
		height: 1rem;
	}
}

@media(min-width:1440px) {
	.btn-sz {
		font-size: 2rem !important;
	}

	.flexbox-container {
		width: 3rem;
		height: 1.5rem;
	}
}

@media(min-width:2560px) {
	.btn-sz {
		font-size: 3rem !important;
	}

	.flexbox-container {
		width: 3rem;
		height: 1.5rem;
	}
}