.analysis {
  font-size: 0.75rem !important;
	font-family: "UbuntuRegular" !important;
	line-height: 1;
}
small{
	font-size: 70% !important;
	line-height: 1 !important;
}
/* @media(min-width:320px) {
	.a-height{
		min-height: 5vh;
	}
}

@media(min-width:425px) {
	.a-height{
		min-height: 6vh;
	}
}
@media(min-width:576px) {
	.a-height{
		min-height: 30vh;
	}
}

@media(min-width:640px) {
	.a-height{
		min-height: 10vh;
	}
}


@media(min-width:768px) {
	.a-height{
		min-height: 5vh;
	}
}
@media(min-width:850px) {
	.a-height{
		min-height: 12vh;
	}
}
@media(min-width:1024px) {
	.a-height{
		min-height: 32vh;
	}
}
@media(min-width:1200px) {
	.a-height{
		min-height: 12vh;
	}
}
@media(min-width:1440px) {
	.a-height{
		min-height: 58vh;
	}
} */
