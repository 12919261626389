.zakat {
	padding: 16px 0;
}

.zakat .img-wrap img {
	width: 100%;
	height: auto;
	border-radius: 20px;
}

.zakat h1 {
	/* font-family: "El Messiri", cursive; */
	font-size: 45px;
	line-height: 70px;
	font-weight: 700;
	color: #004018;
	margin-bottom: 30px;
}

.zakat h1 span {
	font-weight: 600;
	color: #2aa457;
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.zakat .img-wrap .fundrise {
		margin-top: -180px;
		margin-left: 50px !important;
	}

	.zakat .img-wrap .counter {
		margin-left: auto !important;
	}

	.zakat .img-wrap .info-wrap {
		position: static !important;
		margin-left: auto;
		margin-top: 50px;
	}
}

@media (min-width: 250px) and (max-width: 767.98px) {
	.zakat .img-wrap .fundrise {
		margin-top: -70px !important;
		margin-left: 10px !important;
	}

	.zakat .img-wrap .counter {
		position: relative;
		margin-left: auto !important;
		/* margin-top: 50px; */
	}

	.zakat .img-wrap .d-flex {
		display: block !important;
	}

	.zakat .img-wrap .info-wrap {
		position: static !important;
		width: 100% !important;
		margin-top: 100px;
	}
}

.zakat .img-wrap {
	position: relative;
}

.zakat .img-wrap .info-wrap {
	width: 400px;
	height: auto;
	background-color: #ffffff;
	border-radius: 15px;
	box-shadow: 0 0 30px rgba(0, 64, 25, 0.5);
	padding: 20px;
	position: absolute;
	right: -200px;
	top: 100px;
}

.zakat .img-wrap .info-wrap p {
	font-size: 14px;
	color: #004018;
	/* line-height: 25px; */
	display: -webkit-box;
	max-width: 100%;
	margin: 0 auto;
	-webkit-line-clamp: 7;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 22px;
	margin-bottom: 25px;
}

.zakat .img-wrap .info-wrap p span {
	/* font-family: "El Messiri", cursive; */
	color: #2aa457;
	font-size: 20px;
	font-weight: 600;
}

.zakat .short-desc {
	margin: 30px 0;
}

.zakat .short-desc p {
	font-size: 14px;
	color: #004018;
	line-height: 25px;
}

.zakat .short-desc p span {
	/* font-family: "El Messiri", cursive; */
	font-weight: 600;
	font-size: 20px;
	color: #2aa457;
}